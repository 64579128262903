import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "../menu/menu"
import MenuIcon from '@mui/icons-material/Menu';
import IconLogo from '../../../static/svgs/logos/chc-logo-23.svg'
import { Box, useMediaQuery, useTheme, Container, Grid } from '@mui/material';
import { IconButton, Link } from "gatsby-theme-material-ui"

const Header = props => {
  return (
    <Box sx={{ zIndex: 2 }}>
      <HeaderInner {...props} />
    </Box>
  )
}

const HeaderInner = props => {

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))


  const data = useStaticQuery(graphql`
  query HeaderQuery {

    wp {
      companyDetails {
        companyDetails {
          companyname
          companyemail
          companyphonenumber
          companydescription
        }
      }
    }
    mobileExtraMenu: wpMenu(slug: {eq: "mobile-extra-menu"}) {
      menuItems {
        items: nodes {
          url
          path
          title
          childItems {
            subMenu: nodes {
              title
              url
              path
              target
              label
              parentId
              menu_feature {
                menuFeature {
                  title
                  showMenuFeature
                  excerpt
                  fieldGroupName
                  images {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                            width: 1920
                        )
                      }
                    }
                  }
                }
              }
              menu_icon {
                menuIcon {
                  showMenuText
                  showMenuIcon
                  selectMenuIcon
                  fieldGroupName
                }
              }
            }
          }
          label
          menu_icon {
            menuIcon {
              selectMenuIcon
              showMenuIcon
              showMenuText
            }
          }
        }
      }
      slug
      name
    }

      topMenu: wpMenu(slug: {eq: "top-nav"}) {
            menuItems {
              items: nodes {
                url
                path
                title
                childItems {
                  subMenu: nodes {
                    title
                    url
                    path
                    target
                    label
                    parentId
                    menu_feature {
                      menuFeature {
                        title
                        showMenuFeature
                        excerpt
                        fieldGroupName
                        images {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                  width: 151
                                  height: 151
                              )
                            }
                          }
                        }
                      }
                    }
                    menu_icon {
                      menuIcon {
                        showMenuText
                        showMenuIcon
                        selectMenuIcon
                        fieldGroupName
                      }
                    }
                  }
                }
                label
                menu_icon {
                  menuIcon {
                    selectMenuIcon
                    showMenuIcon
                    showMenuText
                  }
                }
              }
            }
            slug
            name
      }

      bottomMenu: wpMenu(slug: {eq: "main"}) {
            menuItems{
              items: nodes {
                url
                path
                title
                parentId
                menu_feature {
                  menuFeature {
                    showMenuFeature
                    images {
                      sourceUrl
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                              width: 151
                              height: 151
                          ) 
                        }
                      }                    
                    }
                  }
                }
                childItems {
                  subMenu: nodes {
                    
                      menu_feature {
                        menuFeature {
                          title
                          showMenuFeature
                          excerpt
                          fieldGroupName
                          images {
                            altText
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 151
                                  height: 151
                                  ) 
                              }
                            }
                          }
                        }
                      }
                      menu_icon {
                        menuIcon {
                          showMenuText
                          showMenuIcon
                          selectMenuIcon
                          fieldGroupName
                        }
                      }
                      title
                      url
                      target
                      label
                      path
                  }
                }
                label
              }
            }
            slug
            name
      }
    
  }
  `)

  const [bottomMenuItems, setBottomMenuItems] = useState(data.bottomMenu)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))


  useEffect(() => {
    if (isMobile) {
      data.bottomMenu.menuItems.items = [
        ...data.bottomMenu.menuItems.items,
        ...data.mobileExtraMenu.menuItems.items,
      ]

      setBottomMenuItems(data.bottomMenu)
    }
    else {
      setBottomMenuItems(data.bottomMenu)
    }

  }, [setBottomMenuItems, data])

  const topMenu = data.topMenu;
  const bottomMenu = bottomMenuItems

  const defaultState = {
    burger: false,
  }
  const [menuState, updateMenuStatus] = useState(defaultState);

  const subMenuClose = (select) => {
    if (select) {
      updateMenuStatus({
        burger: false,
      })
    }
  }

  const burgerClick = () => {
    updateMenuStatus({
      burger: !menuState.burger,
    })
  }

  return (

    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} lg={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: 1 }}>

          <Link to={"/"} aria-label="Return to the home page" sx={{ display: "flex", alignItems: "center" }}>
            <IconLogo width="150px" />
          </Link>

          <Box sx={{ display: { lg: "none" } }}>
            <Burger active={menuState.burger} onClick={burgerClick} />
          </Box>
        </Grid>
        <Grid item xs={0} lg={10} sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          py: 1,
        }}>
          <Menu
            menu={topMenu}
            name="topMenu"
            open={isDesktop}
          />
          <Menu
            menu={bottomMenu}
            name="BottomMenu"
            open={menuState.burger || isDesktop}
            onClose={subMenuClose}
          >
          </Menu>
        </Grid>
      </Grid>
    </Container>
  )
}

const Burger = ({ active, onClick }) => {

  const updateBurger = (e) => {
    if (onClick) {
      onClick();
    }
    // document.body.classList.toggle("noScroll")
  }
  return (
    <>
      <IconButton
        sx={{
          display: { lg: "none" },
        }}
        aria-label="Open Menu"
        component="button"
        onClick={(e) => updateBurger(e)}
        color="primary.main"
      >
        <MenuIcon width="2rem" />
      </IconButton>
    </>
  )
}

export default Header
