import React from "react"
import { Box } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"

const FeefoBadge = ({ merchantid }) => {

  return (
    <Box xs={{
      minHeight: "8vh"
    }}
      className="FeefoBadgeWrapper">
      <Box>
        <a href={`https://www.feefo.com/en-GB/reviews/${merchantid}?displayFeedbackType=BOTH&timeFrame=ALL`} target="_blank" rel="noreferrer">
          <StaticImage
            src="https://api.feefo.com/api/logo?merchantidentifier=cornish-holiday-cottages&template=Service-Stars-White-200x51.png"
            title="What our customers say about us"
            alt="service rating"
          />
        </a>
      </Box>
    </Box>
  )
}

export default FeefoBadge
