import React from "react"
import { Typography, Box, useTheme, Link } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import ListMenu from "./listMenu"
import FeefoBadge from "../feefo/feefoBadge"
import FeefoDatedLogo from '../../../static/svgs/inline/ptrustedservicewhiteout.svg'

const FooterMenu = props => {
  const menu = props.menu;

  const theme = useTheme();

  // parent menu items
  const menuItems = () => {
    return (menu.menuItems.items.filter(i => !i.parentId));
  }

  const renderMenuTitle = (menuItem, i) => {
    return menuItem.label &&
      menuItem?.menu_icon.menuIcon.showMenuText === true ? (
      <Typography align="left" variant="body2" component="h3" sx={{ fontWeight: " bold", marginBottom: theme.spacing(1), color: "white.main" }}>{menuItem.label}</Typography>
    ) : (
      ""
    )
  }
  const renderFooterMenu = menuItems().map((menuItem, i) => {
    return (
      <div
        key={"footerMenu-" + i}
        className={
          "menuBlock menu-" + menuItem.label.replace(/\s+/g, "-").toLowerCase()
        }
      >
        {renderMenuTitle(menuItem, i)}
        <ListMenu
          menuItem={menuItem?.childItems?.subMenu}
          templateName={menuItem.label.replace(/\s+/g, "-").toLowerCase()}
        ></ListMenu>
      </div>
    )
  })

  return (
    <Box sx={{
      paddingTop: "calc(1.4 * 4rem)",
      paddingBottom: "calc(1.4 * 4rem)",
      display: { xs: "grid", md: "grid" },
      flexDirection: "column",
      gridColumnGap: { xs: "0.5rem", md: "1rem" },
      gridRowGap: {xs: "1rem", md: "1rem" },
      gridTemplateColumns: { xs: "repeat(4, 1fr)", sm: "repeat(4, 1fr)", md: "repeat(4, 1fr)" },
      gridTemplateRows: { xs: "repeat(4, auto)", sm: "repeat(6, auto)", md: "repeat(3, auto)", lg: "repeat(3, auto)" },
      //gridTemplateAreas: { xs: "'one two three four five . six .'", sm: "'one two three four five six'", md: "'one two three four five six'" },

      "& div:nth-of-type(1)": {
        gridColumn: { xs: "1/5", md: "1/2" },
        gridRow: { xs: "1/1", md: "1/1" },
      },

      "& div:nth-of-type(2)": {
        gridColumn: { xs: "1/5", md: "2/3" },
        gridRow: { xs: "2/2", md: "1/1" },
      },

      "& div:nth-of-type(3)": {
        gridColumn: { xs: "1/5", md: "3/4" },
        gridRow: { xs: "3/3", md: "1/1" },

      },

      "& div:nth-of-type(4)": {
        gridColumn: { xs: "1/5", md: "4/5" },
        gridRow: { xs: "4/4", md: "1/1" },
      },

      "& div:nth-of-type(5)": {
        gridColumn: { xs: "1/5", md: "1/auto" },
        gridRow: { xs: "11/auto", md: "5/auto" },
      },

      "& .menu": {
        a: {
          color: "white",
        },
      },
      "& .menuBlock": {
        marginBottom: { xs: 1, md: 0 },
      }
    }} className="footerTemplate">
      {renderFooterMenu}
      <Box sx={{
        color: "white.main",
        gridColumn: { xs: "1/5", md: "1/2" },
        gridRow: { xs: "14/auto", md: "3/3" },
        display: "flex",
        alignItems: "center",
      }} className="siteCredit">
        <Typography variant="body1" sx={{ fontSize: 14 }} color="white.main"> © {new Date().getFullYear()} Cornish Holiday Cottages,<br></br>
          The Square, Mawnan Smith,<br></br>
          Falmouth, TR11 5EP</Typography>
      </Box>
      <Box sx={{
        gridColumn: { xs: "1/5", sm: "4/5", md: "2/2" },
        gridRow: { xs: "6/auto", sm: "7/auto", md: "3/3" },
        display: "flex",
        alignItems: "center",
      }}>
        <FeefoBadge merchantid="cornish-holiday-cottages" badge="Service-Stars-White-200x51" />
      </Box>

      <Box sx={{
        gridColumn: { xs: "1/2", sm: "1/2", md: "3/3" },
        gridRow: { xs: "7/auto", md: "3/3" },
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
      }}>
        <FeefoDatedLogo width="80px" />
      </Box>

      <Box sx={{
        gridColumn: { xs: "1/5", sm: "3/4", md: "3/3" },
        gridRow: { xs: "6/auto", sm: "7/auto", md: "3/3" },
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center", md: "center" },
        justifyContent: { xs: "flex-end", sm: "flex-end", md: "flex-end" },
        justifySelf: { xs: "flex-end", sm: "center", md: "unset" }
      }}>
        <Box
          sx={{
            width: { xs: "80px", sm: "120px", md: "110px" },
          }}>
          <StaticImage
            alt="We are a living wage employer"
            src="../../../static/images/LW_Employer_logo_transparent_0.png"
          //style={{ width: "100%" }}
          />
        </Box>
      </Box>

      <Box sx={{
        gridColumn: { xs: "2/5", sm: "2/3", md: "4/4" },
        gridRow: { xs: "7/auto", md: "3/3" },
        filter: "invert(1)",
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: 'flex-end', md: 'center' }
      }}>
        <Link
          href="https://ecologi.com/cornishholidaycottag?r=61ee6b2cbad73fcaa227ef83"
          target="_blank"
          rel="noopener noreferrer"
          title="View our Ecologi profile"
          sx={{ maxWidth: "160px", display: "inline-flex", alignItems: "center" }}
        >
          <StaticImage
            alt="We offset our carbon footprint via Ecologi"
            src="https://api.ecologi.com/badges/cpw/61ee6b2cbad73fcaa227ef83?black=true&landscape=true"
            style={{ width: "100%" }}
          />
        </Link>
      </Box>


      <Box sx={{
        gridColumn: { xs: "1/3", sm: "1/3", md: "5/5" },
        gridRow: { xs: "8/auto", md: "3/3" },
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: 'flex-start', md: 'center' }
      }}>
        <Link
          href="https://www.thegreenwebfoundation.org/"
          target="_blank"
          rel="noopener noreferrer"
          title="The Green Web Foundation"
          sx={{ maxWidth: "160px", display: "inline-flex", alignItems: "center", }}
        >
         <img
          alt="This website runs on green hosting - verified by thegreenwebfoundation.org"
          src="https://app.greenweb.org/api/v3/greencheckimage/www.cornishholidaycottages.com?nocache=true"
          style={{ width: "100%" }}
        />
        </Link>
      </Box>

      <Box sx={{
        color: "white.main",
        gridColumn: { xs: "1/5", md: "3/6" },
        gridRow: { xs: "9/auto", sm: "8/auto", md: "5/5" },
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: 'flex-end', md: 'flex-end' }
      }}>
        <Typography variant="body1" sx={{ fontSize: 14 }}>We are members of PASC, <Link href="https://www.visitcornwall.com/">Visit Cornwall</Link> and the <Link href="https://betterbusinessact.org/">Better Business Act</Link>.</Typography> 
      </Box>


    </Box>
  )
}

export default FooterMenu
